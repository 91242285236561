import { lazy, Suspense, useEffect } from "react";
/// Components
import Index from "./jsx/index";
// import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "./jsx/pages/Login";
import SignUp from "./jsx/pages/Registration";
import ForgotPassword from "./jsx/pages/ForgotPassword";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { app } from "./Services/dbService";

// // action
// import { checkAutoLogin } from "./services/AuthService";
// import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

function App(props) {
  const authType = useSelector((x) => x.auth.type);
  const location = useLocation();
  // if (authType === ' ' || 'anon-user')
  // return (
  //   <>
  //     <Navigate to="/login" />
  //   </>
  // );
  //   const dispatch = useDispatch();
  //   useEffect(() => {
  //     checkAutoLogin(dispatch, props.history);
  //   }, [dispatch, props.history]);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
    </Switch>
  );
  // console.log("currentUser::",app.currentUser)
  if (!app.currentUser || (app.currentUser && app.currentUser.providerType !== 'local-userpass')) {
    return (
      <>
        <Redirect to="/login" state={{ from: location }} />
        {routes}
      </>
    );
    // return (
    //   <div className="vh-100">
    //     <Suspense
    //       fallback={
    //         <div id="preloader">
    //           <div className="sk-three-bounce">
    //             <div className="sk-child sk-bounce1"></div>
    //             <div className="sk-child sk-bounce2"></div>
    //             <div className="sk-child sk-bounce3"></div>
    //           </div>
    //         </div>
    //       }
    //     >
    //       {routes}
    //     </Suspense>
    //   </div>
    // );
  } else {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
           {/* <Redirect to="/dashboard" state={{ from: location }} /> */}
          <Index />
        </Suspense>
      </>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: isAuthenticated(state),
//   };
// };

export default App;
