import React from "react";
import "../../../css/dashboard.css";
import Orders from "../../components/Orders/Orders";
import Approvals from "../../components/Apporvals/Approvals";
import Customers from "../../components/Customers/Customers";
import Providers from "../../components/Providers/Providers";

const DashBoard = () => {
  return (
    <div className="dashboard-main-div">
      <div className="dashboard-row">
        <h5>Last 10 Orders</h5>
        <Orders />
      </div>
      <div className="dashboard-row">
        <h5>For Approval</h5>
        <Approvals />
      </div>
      <div className="dashboard-row">
        <h5>Last 10 Customers</h5>
        <Customers dashboard={true} />
      </div>
      <div className="dashboard-row">
        <h5>Last 10 Providers</h5>
        <Providers dashboard={true}/>
      </div>
    </div>
  );
};

export default DashBoard;
