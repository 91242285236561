import React, { useState } from "react";
import { useAllCustomer } from "../../../Common/Hooks/queryCalls";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EditCustomer from "./EditCustomer";
import { DeleteCustomer } from "../../../Services/dbService";
import { LottieLoading } from "../../../Common/LottieLoading";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";


const Customers = ({ dashboard = false }) => {
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [specificCustomer, setSpecificCustomer] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { data, isLoading, refetch } = useAllCustomer();
  // console.log("allCustomer::", data);
  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  // if (!data) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       Loading
  //     </h1>
  //   );
  // }

  if (data.length == 0) {
    return (
      <h5
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        No Items Available
      </h5>
    );
  }

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setSpecificCustomer({});
  };

  const handleEdit = (item) => {
    setSpecificCustomer(item);
    setOpen(true);
    setEdit(true);
  };

  const handleClickDelete = async () => {
    // console.log("item going to be deleted::", deleteItem);
    setDeleteLoading(true);
    const result = await DeleteCustomer({
      itemId: deleteItem?._id,
    });
    setOpenDialog(false);
    // console.log("branch deleted successfully:-", result);
    setDeleteLoading(false);
    refetch();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteItem({});
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this Customer?"}
        </DialogTitle>

        <DialogActions>
          {!isDeleteLoading ? (
            <Button onClick={handleCloseDialog}>No</Button>
          ) : (
            ""
          )}
          {isDeleteLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Yes
            </LoadingButton>
          ) : (
            <Button onClick={handleClickDelete} autoFocus>
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowX: "scroll",
            },
          }}
        >
          <EditCustomer
            setOpen={setOpen}
            edit={edit}
            specificCustomer={specificCustomer}
            refetch={refetch}
          />
        </Box>
      </Modal>
      <div>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    First Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Last Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    // style={{ minWidth: column.minWidth }}
                  >
                    Number
                  </TableCell>
                  {!dashboard && (
                    <TableCell
                      align="center"
                      sx={tableHeadStyle}
                      // style={{ minWidth: column.minWidth }}
                    >
                      Status
                    </TableCell>
                  )}

                  {!dashboard && (
                    <TableCell
                      align="center"
                      sx={tableHeadStyle}
                      // style={{ minWidth: column.minWidth }}
                    >
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item._id}
                      >
                        <TableCell align="center" sx={tableHeadStyle}>
                          {item?.firstName}
                        </TableCell>
                        <TableCell align="center" sx={tableHeadStyle}>
                          {item?.lastName}
                        </TableCell>
                        <TableCell align="center" sx={tableHeadStyle}>
                          {item?.email}
                        </TableCell>
                        <TableCell align="center" sx={tableHeadStyle}>
                          {`+971 ${item?.number}`}
                        </TableCell>
                        {!dashboard && (
                          <TableCell align="center" sx={tableHeadStyle}>
                            {item?.metadata?.isActive ? (
                              <CheckCircleIcon fontSize="small" />
                            ) : (
                              <CancelIcon fontSize="small" />
                            )}
                          </TableCell>
                        )}
                        {!dashboard && (
                          <TableCell align="center">
                            {
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {/* <IconButton onClick={() => {}}>
                  <VisibilityIcon fontSize="small" />
                </IconButton> */}
                                <IconButton
                                  onClick={() => {
                                    handleEdit(item);
                                  }}
                                >
                                  <EditNoteIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setDeleteItem(item);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </div>
                            }
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={tableHeadStyle}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
};

export default Customers;
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
};
