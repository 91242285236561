import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  useNotApprovedProviders,
  useNotApprovedAddons,
  useNotApprovedBranches,
  useNotApprovedMenus,
  useNotActiveReview,
} from "../../../Common/Hooks/queryCalls";
import ViewProviderDetails from "./ViewProviderDetails";
import ViewBranchDetails from "./ViewBranchDetails";
import ViewMenuDetails from "./ViewMenuDetails";
import ViewModifierDetails from "./ViewModifierDetails";
import {
  UpdateAddonApprove,
  UpdateBranchApprove,
  UpdateMenuApprove,
  UpdateProviderApprove,
} from "../../../Services/dbService";
import { LottieLoading } from "../../../Common/LottieLoading";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Approvals = () => {
  const [isLoadingProvider, setIsLoadingProvider] = useState(false);
  const [isLoadingBranch, setIsLoadingBranch] = useState(false);
  const [isLoadingMenu, setIsLoadingMenu] = useState(false);
  const [isLoadingAddon, setIsLoadingAddon] = useState(false);
  const [openDialogProvider, setOpenDialogProvider] = useState(false);
  const [openDialogBranch, setOpenDialogBranch] = useState(false);
  const [openDialogMenu, setOpenDialogMenu] = useState(false);
  const [openDialogAddon, setOpenDialogAddon] = useState(false);
  const [specificProvider, setSpecificProvider] = useState({});
  const [specificBranch, setSpecificBranch] = useState({});
  const [specificMenu, setSpecificMenu] = useState({});
  const [specificAddon, setSpecificAddon] = useState({});
  const [approveProvider, setApproveProvider] = useState({});
  const [approveBranch, setApproveBranch] = useState({});
  const [approveMenu, setApproveMenu] = useState({});
  const [approveAddon, setApproveAddon] = useState({});
  const [open, setOpen] = useState(false);
  const [openBranchModal, setOpenBranchModal] = useState(false);
  const [openMenuModal, setOpenMenuModal] = useState(false);
  const [openAddonModal, setOpenAddonModal] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
    setSpecificProvider({});
  };
  const handleCloseBranch = () => {
    setOpenBranchModal(false);
    setSpecificBranch({});
  };
  const handleCloseMenu = () => {
    setOpenMenuModal(false);
    setSpecificMenu({});
  };
  const handleCloseAddon = () => {
    setOpenAddonModal(false);
    setSpecificAddon({});
  };
  const handleCloseDialogProvider = () => {
    setOpenDialogProvider(false);
    setApproveProvider({});
  };

  const handleCloseDialogBranch = () => {
    setOpenDialogBranch(false);
    setApproveBranch({});
  };

  const handleCloseDialogMenu = () => {
    setOpenDialogMenu(false);
    setApproveMenu({});
  };

  const handleCloseDialogAddon = () => {
    setOpenDialogAddon(false);
    setApproveAddon({});
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const NotApprovedProviders = useNotApprovedProviders();
  // console.log("NotApprovedProviders::", NotApprovedProviders?.data);

  const NotApprovedBranches = useNotApprovedBranches();
  // console.log("NotApprovedBranches::", NotApprovedBranches?.data);
  const NotApprovedMenus = useNotApprovedMenus();
  // console.log("NotApprovedMenus::", NotApprovedMenus?.data);
  const NotApprovedAddons = useNotApprovedAddons();
  // console.log("NotApprovedAddons::", NotApprovedAddons?.data);
  // const NotActiveReview = useNotActiveReview();
  // console.log("NotActiveReview::", NotActiveReview?.data);

  const handleUpdateProvider = async () => {
    setIsLoadingProvider(true);
    const result = await UpdateProviderApprove({
      item: approveProvider,
    });
    // console.log("EditProvider:", result);
    setOpenDialogProvider(false);
    setIsLoadingProvider(false);
    NotApprovedProviders.refetch();
  };

  const handleUpdateBranch = async () => {
    setIsLoadingBranch(true);
    const result = await UpdateBranchApprove({
      item: approveBranch,
    });
    // console.log("EditBranch:", result);
    setOpenDialogBranch(false);
    setIsLoadingBranch(false);
    NotApprovedBranches.refetch();
  };

  const handleUpdateMenu = async () => {
    setIsLoadingMenu(true);
    const result = await UpdateMenuApprove({
      item: approveMenu,
    });
    // console.log("EditMenu:", result);
    setOpenDialogMenu(false);
    setIsLoadingMenu(false);
    NotApprovedMenus.refetch();
  };

  const handleUpdateAddon = async () => {
    setIsLoadingAddon(true);
    const result = await UpdateAddonApprove({
      item: approveAddon,
    });
    // console.log("EditAddon:", result);
    setOpenDialogAddon(false);
    setIsLoadingAddon(false);
    NotApprovedAddons.refetch();
  };

  if (NotApprovedProviders.isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  // if (!data) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       Loading
  //     </h1>
  //   );
  // }

  // if (NotApprovedProviders.data.length == 0) {
  //   return (
  //     <h5
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       No Items Available
  //     </h5>
  //   );
  // }

  if (NotApprovedBranches.isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  // if (!data) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       Loading
  //     </h1>
  //   );
  // }

  // if (NotApprovedBranches.data.length == 0) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       No Items Available
  //     </h1>
  //   );
  // }

  if (NotApprovedMenus.isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  // if (!data) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       Loading
  //     </h1>
  //   );
  // }

  // if (NotApprovedMenus.data.length == 0) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       No Items Available
  //     </h1>
  //   );
  // }

  if (NotApprovedAddons.isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  // if (!data) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       Loading
  //     </h1>
  //   );
  // }

  // if (NotApprovedAddons.data.length == 0) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       No Items Available
  //     </h1>
  //   );
  // }

  return (
    <>
      <div>
        <Box sx={{ bgcolor: "background.paper" }}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="scrollable"
              aria-label="full width tabs example"
              sx={{ backgroundColor: "#2b445a" }}
            >
              <Tab sx={tableHeadStyle} label="Provider" {...a11yProps(0)} />
              <Tab sx={tableHeadStyle} label="Branch" {...a11yProps(1)} />
              <Tab sx={tableHeadStyle} label="Menu" {...a11yProps(2)} />
              <Tab sx={tableHeadStyle} label="Addons" {...a11yProps(3)} />
              {/* <Tab sx={tableHeadStyle} label="Review" {...a11yProps(4)} /> */}
            </Tabs>
          </AppBar>
          {/* <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          > */}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Dialog
              open={openDialogProvider}
              onClose={handleCloseDialogProvider}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure want to approve?"}
              </DialogTitle>

              <DialogActions>
                {!isLoadingProvider ? (
                  <Button onClick={handleCloseDialogProvider}>No</Button>
                ) : (
                  ""
                )}
                {isLoadingProvider ? (
                  <LoadingButton
                    size="small"
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    yes
                  </LoadingButton>
                ) : (
                  <Button onClick={handleUpdateProvider} autoFocus>
                    Yes
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  "@media (max-width: 600px)": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflowY: "scroll",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "70%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflowY: "scroll",
                  },
                  "@media (min-width: 970px)": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "70%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflowX: "scroll",
                  },
                }}
              >
                {specificProvider && (
                  <ViewProviderDetails
                    setOpen={setOpen}
                    specificProvider={specificProvider}
                  />
                )}
              </Box>
            </Modal>
            <Stack spacing={2}>
              <Item>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      {NotApprovedProviders?.data?.length == 0 && (
                        <caption style={{ fontFamily: "outfit" }}>
                          No Items Available
                        </caption>
                      )}
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Type
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Subscription
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Stripe Account
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Show Details
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Approve
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {NotApprovedProviders?.data
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item._id}
                              >
                                <TableCell align="center" sx={tableHeadStyle}>
                                  {`${item?.name?.en}(${item?.name?.ar})`}
                                </TableCell>
                                <TableCell align="center" sx={tableHeadStyle}>
                                  {item?.type || "-"}
                                </TableCell>
                                <TableCell align="center" sx={tableHeadStyle}>
                                  {`${item?.subscription?.plan?.nickname}  (${item?.subscription?.status})` ||
                                    "-"}
                                </TableCell>

                                <TableCell align="center" sx={tableHeadStyle}>
                                  {item?.stripeAccount &&
                                  item?.stripeAccount?.details_submitted &&
                                  item?.stripeAccount?.charges_enabled ? (
                                    <CheckCircleIcon />
                                  ) : null}
                                  {!item?.stripeAccount?.details_submitted ||
                                  !item?.stripeAccount?.charges_enabled ? (
                                    <CancelIcon />
                                  ) : null}
                                </TableCell>
                                <TableCell align="center" sx={tableHeadStyle}>
                                  <Button
                                    sx={tableHeadStyle}
                                    onClick={() => {
                                      setOpen(true);
                                      setSpecificProvider(item);
                                    }}
                                  >
                                    Show Details
                                  </Button>
                                </TableCell>

                                <TableCell align="center" sx={tableHeadStyle}>
                                  <Button
                                    variant="outlined"
                                    sx={tableHeadStyle}
                                    startIcon={<TaskAltIcon />}
                                    onClick={() => {
                                      setOpenDialogProvider(true);
                                      setApproveProvider(item);
                                    }}
                                  >
                                    Approve
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    sx={tableHeadStyle}
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={NotApprovedProviders?.data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Item>
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Dialog
              open={openDialogBranch}
              onClose={handleCloseDialogBranch}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure want to approve?"}
              </DialogTitle>

              <DialogActions>
                {!isLoadingBranch ? (
                  <Button onClick={handleCloseDialogBranch}>No</Button>
                ) : (
                  ""
                )}
                {isLoadingBranch ? (
                  <LoadingButton
                    size="small"
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    yes
                  </LoadingButton>
                ) : (
                  <Button onClick={handleUpdateBranch} autoFocus>
                    Yes
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Modal
              open={openBranchModal}
              onClose={handleCloseBranch}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  "@media (max-width: 600px)": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflowY: "scroll",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "70%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflowY: "scroll",
                  },
                  "@media (min-width: 970px)": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "70%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflowX: "scroll",
                  },
                }}
              >
                {specificBranch && (
                  <ViewBranchDetails
                    setOpen={setOpen}
                    specificBranch={specificBranch}
                  />
                )}
              </Box>
            </Modal>
            <Stack spacing={2}>
              <Item>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      {NotApprovedBranches?.data?.length == 0 && (
                        <caption style={{ fontFamily: "outfit" }}>
                          No Items Available
                        </caption>
                      )}
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Provider
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Show Details
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Approve
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {NotApprovedBranches?.data
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item._id}
                              >
                                <TableCell align="center" sx={tableHeadStyle}>
                                  {`${item?.name?.en}(${item?.name?.ar})`}
                                </TableCell>
                                <TableCell align="center" sx={tableHeadStyle}>
                                  {`${item?.provider?.name?.en}(${item?.provider?.name?.ar})`}
                                </TableCell>

                                <TableCell align="center" sx={tableHeadStyle}>
                                  <Button
                                    sx={tableHeadStyle}
                                    onClick={() => {
                                      setOpenBranchModal(true);
                                      setSpecificBranch(item);
                                    }}
                                  >
                                    Show Details
                                  </Button>
                                </TableCell>

                                <TableCell align="center" sx={tableHeadStyle}>
                                  <Button
                                    variant="outlined"
                                    sx={tableHeadStyle}
                                    startIcon={<TaskAltIcon />}
                                    onClick={() => {
                                      setOpenDialogBranch(true);
                                      setApproveBranch(item);
                                    }}
                                  >
                                    Approve
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    sx={tableHeadStyle}
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={NotApprovedBranches?.data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Item>
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <Dialog
              open={openDialogMenu}
              onClose={handleCloseDialogMenu}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure want to approve?"}
              </DialogTitle>

              <DialogActions>
                {!isLoadingMenu ? (
                  <Button onClick={handleCloseDialogMenu}>No</Button>
                ) : (
                  ""
                )}
                {isLoadingMenu ? (
                  <LoadingButton
                    size="small"
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    Yes
                  </LoadingButton>
                ) : (
                  <Button onClick={handleUpdateMenu} autoFocus>
                    Yes
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Modal
              open={openMenuModal}
              onClose={handleCloseMenu}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  "@media (max-width: 600px)": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflowY: "scroll",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "70%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflowY: "scroll",
                  },
                  "@media (min-width: 970px)": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "70%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflowX: "scroll",
                  },
                }}
              >
                {specificMenu && (
                  <ViewMenuDetails
                    setOpen={setOpen}
                    specificMenu={specificMenu}
                  />
                )}
              </Box>
            </Modal>
            <Stack spacing={2}>
              <Item>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      {NotApprovedMenus?.data?.length == 0 && (
                        <caption style={{ fontFamily: "outfit" }}>
                          No Items Available
                        </caption>
                      )}
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Provider
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Show Details
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Approve
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {NotApprovedMenus?.data
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item._id}
                              >
                                <TableCell align="center" sx={tableHeadStyle}>
                                  {`${item?.name?.en}(${item?.name?.ar})`}
                                </TableCell>
                                <TableCell align="center" sx={tableHeadStyle}>
                                  {`${item?.provider?.name?.en}(${item?.provider?.name?.ar})`}
                                </TableCell>

                                <TableCell align="center" sx={tableHeadStyle}>
                                  <Button
                                    sx={tableHeadStyle}
                                    onClick={() => {
                                      setOpenMenuModal(true);
                                      setSpecificMenu(item);
                                    }}
                                  >
                                    Show Details
                                  </Button>
                                </TableCell>

                                <TableCell align="center" sx={tableHeadStyle}>
                                  <Button
                                    variant="outlined"
                                    sx={tableHeadStyle}
                                    startIcon={<TaskAltIcon />}
                                    onClick={() => {
                                      setOpenDialogMenu(true);
                                      setApproveMenu(item);
                                    }}
                                  >
                                    Approve
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    sx={tableHeadStyle}
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={NotApprovedMenus?.data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Item>
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <Dialog
              open={openDialogAddon}
              onClose={handleCloseDialogAddon}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure want to approve?"}
              </DialogTitle>

              <DialogActions>
                {!isLoadingAddon ? (
                  <Button onClick={handleCloseDialogAddon}>No</Button>
                ) : (
                  ""
                )}
                {isLoadingAddon ? (
                  <LoadingButton
                    size="small"
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    Yes
                  </LoadingButton>
                ) : (
                  <Button onClick={handleUpdateAddon} autoFocus>
                    Yes
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            <Modal
              open={openAddonModal}
              onClose={handleCloseAddon}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  "@media (max-width: 600px)": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflowY: "scroll",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "70%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflowY: "scroll",
                  },
                  "@media (min-width: 970px)": {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "70%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflowX: "scroll",
                  },
                }}
              >
                {specificAddon && (
                  <ViewModifierDetails
                    setOpen={setOpen}
                    specificAddon={specificAddon}
                  />
                )}
              </Box>
            </Modal>
            <Stack spacing={2}>
              <Item>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      {NotApprovedAddons?.data?.length == 0 && (
                        <caption style={{ fontFamily: "outfit" }}>
                          No Items Available
                        </caption>
                      )}
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Provider
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Show Details
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Approve
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {NotApprovedAddons?.data
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item._id}
                              >
                                <TableCell align="center" sx={tableHeadStyle}>
                                  {`${item?.name?.en}(${item?.name?.ar})`}
                                </TableCell>
                                <TableCell align="center" sx={tableHeadStyle}>
                                  {`${item?.provider?.name?.en}(${item?.provider?.name?.ar})`}
                                </TableCell>

                                <TableCell align="center" sx={tableHeadStyle}>
                                  <Button
                                    sx={tableHeadStyle}
                                    onClick={() => {
                                      setOpenAddonModal(true);
                                      setSpecificAddon(item);
                                    }}
                                  >
                                    Show Details
                                  </Button>
                                </TableCell>

                                <TableCell align="center" sx={tableHeadStyle}>
                                  <Button
                                    variant="outlined"
                                    sx={tableHeadStyle}
                                    startIcon={<TaskAltIcon />}
                                    onClick={() => {
                                      setOpenDialogAddon(true);
                                      setApproveAddon(item);
                                    }}
                                  >
                                    Approve
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    sx={tableHeadStyle}
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={NotApprovedAddons?.data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Item>
            </Stack>
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <Stack spacing={2}>
              <Item>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      {NotApprovedMenus?.data?.length == 0 && (
                        <caption style={{ fontFamily: "outfit" }}>
                          No Items Available
                        </caption>
                      )}
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Provider
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Show Details
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={tableHeadStyle}
                            // style={{ minWidth: column.minWidth }}
                          >
                            Approve
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {NotApprovedMenus?.data
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={item._id}
                              >
                                <TableCell align="center" sx={tableHeadStyle}>
                                  {`${item?.name?.en}(${item?.name?.ar})`}
                                </TableCell>
                                <TableCell align="center" sx={tableHeadStyle}>
                                  {`${item?.provider?.name?.en}(${item?.provider?.name?.ar})`}
                                </TableCell>

                                <TableCell align="center" sx={tableHeadStyle}>
                                  <Button
                                    sx={tableHeadStyle}
                                    onClick={() => {
                                      // setOpen(true);
                                      // setSpecificOrder(item);
                                    }}
                                  >
                                    Show Details
                                  </Button>
                                </TableCell>

                                <TableCell align="center" sx={tableHeadStyle}>
                                  <Button
                                    variant="outlined"
                                    sx={tableHeadStyle}
                                    startIcon={<TaskAltIcon />}
                                    onClick={() => {
                                      // setOpen(true);
                                      // setSpecificOrder(item);
                                    }}
                                  >
                                    Approve
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    sx={tableHeadStyle}
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={NotApprovedMenus?.data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Item>
            </Stack>
          </TabPanel>
          {/* </SwipeableViews> */}
        </Box>
      </div>
    </>
  );
};

export default Approvals;
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
};
