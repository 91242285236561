import React, { useState, useEffect } from "react";
import "../../../css/categories.css";
import { Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { AddOneCategory, UpdateOneCategory } from "../../../Services/dbService";
const types = [
  // {
  //   value: "food",
  //   label: "Food",
  // },
  {
    value: "home-business",
    label: "Home Business",
  },
];

const AddCategories = ({ setOpen, edit, specificCategory = null, refetch }) => {
  const [categoryType, setCategoryType] = useState(null);
  const [checkedShowInApp, setCheckedShowInApp] = React.useState(false);
  const [checkedActive, setCheckedActive] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeShowInApp = (event) => {
    setCheckedShowInApp(event.target.checked);
  };

  const handleChangeActive = (event) => {
    setCheckedActive(event.target.checked);
  };

  // console.log(checkedShowInApp, checkedActive);
  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required(" Name is required"),
    arabicName: Yup.string().required("اسم  is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const addNewCategory = async (data) => {
    // console.log("addNewCategory:", data);
    if (edit) {
      // console.log("editProduct ", data);
      setIsLoading(true);
      const result = await UpdateOneCategory({
        item: specificCategory,
        data,
        checkedShowInApp,
        checkedActive,
        categoryType,
      });
      // console.log("Editresult:", result);
      setOpen(false);
      setIsLoading(false);
      refetch();
    } else {
      // console.log("addProduct ", data);
      setIsLoading(true);
      const result = await AddOneCategory({
        data,
        checkedShowInApp,
        checkedActive,
        categoryType,
      });
      // console.log("Addresult:", result);
      setOpen(false);
      setIsLoading(false);
      refetch();
    }
  };

  useEffect(() => {
    setValue("englishName", specificCategory?.name?.en);
    setValue("arabicName", specificCategory?.name?.ar);
    setCategoryType(specificCategory?.type);
    setCheckedShowInApp(specificCategory?.showInApp);
    setCheckedActive(specificCategory?.metadata?.isActive);
  }, []);
  return (
    <div>
      <form>
        <div className="categories-text-field">
          <div className="categories-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Name*"
              id="fullWidth"
              size="small"
              {...register("englishName", { required: true })}
              error={errors.englishName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.englishName && errors.englishName?.message
                ? errors.englishName?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="categories-text-field-row">
            <TextField
              fullWidth
              label="اسم*"
              id="fullWidth"
              size="small"
              {...register("arabicName", { required: true })}
              error={errors.arabicName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.arabicName && errors.arabicName?.message
                ? errors.arabicName?.message.toString()
                : null}
            </Typography>
          </div>
        </div>
        <div className="categories-text-field">
          <TextField
            id="outlined-select-currency"
            select
            // label="Type*"
            helperText="Please Choose  Type*"
            fullWidth
            value={categoryType}
            onChange={(newValue) => {
              setCategoryType(newValue.target.value);
              // console.log(newValue.target.value);
            }}
          >
            {types.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <div className="categories-toggle-div">
            <FormControlLabel
              control={
                <Switch
                  checked={checkedShowInApp}
                  onChange={handleChangeShowInApp}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Show In App"
              labelPlacement="start"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={checkedActive}
                  onChange={handleChangeActive}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Active"
              labelPlacement="start"
            />
          </div>
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {!isLoading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewCategory)}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  );
};

export default AddCategories;
