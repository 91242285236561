import React from "react";
import { useAllPages } from "../../../Common/Hooks/queryCalls";
import { LottieLoading } from "../../../Common/LottieLoading";

const Pages = () => {
  const { data, isLoading, refetch } = useAllPages();
  // console.log("useAllPages::", data);
  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  // if (!data) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       Loading
  //     </h1>
  //   );
  // }

  if (data.length == 0) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        No Items Available
      </h1>
    );
  }
  return <div>Pages</div>;
};

export default Pages;
