import React, { useState, useEffect } from "react";
import "../../../css/orders.css";
import { formatDate, formatCurrency } from "../../../Services/functions";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  acceptOrder,
  cancelOrder,
  OrderStatusUpdate,
} from "../../../Services/dbService";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

const statusTypes = [
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "received",
    label: "Received",
  },
  {
    value: "accepted",
    label: "Accepted",
  },
  {
    value: "ready",
    label: "Ready",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
  {
    value: "refunded",
    label: "Refunded",
  },
  {
    value: "completed",
    label: "Completed",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const OrderDetail = ({ setOpen, specificOrder, refetch }) => {
  // console.log("sds:", specificOrder);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  useEffect(() => {
    setStatus(specificOrder.status);
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItem({});
  };
  const handleClickUpdate = async () => {
    if (status == "pending") {
      // console.log("pendingf");
      setIsLoading(true);
      const result = await OrderStatusUpdate({
        item: selectedItem,
        orderStatus: status,
      });

      // console.log("pending :-", result);
      if (result) {
        refetch();
        setIsLoading(false);
        setOpenDialog(false);
        setOpen(false);
      }
    }
    if (status == "received") {
      // console.log("receivedf");
      setIsLoading(true);
      const result = await OrderStatusUpdate({
        item: selectedItem,
        orderStatus: status,
      });
      if (result) {
        refetch();
        setOpenDialog(false);
        setIsLoading(false);
        setOpen(false);
      }
      // console.log("received :-", result);
    }
    if (status == "ready") {
      // console.log("readyf");
      setIsLoading(true);
      const result = await OrderStatusUpdate({
        item: selectedItem,
        orderStatus: status,
      });
      if (result) {
        refetch();
        setOpenDialog(false);
        setIsLoading(false);
        setOpen(false);
      }
      // console.log("ready :-", result);
    }
    if (status == "refunded") {
      // console.log("refundedf");
      setIsLoading(true);
      const result = await OrderStatusUpdate({
        item: selectedItem,
        orderStatus: status,
      });
      if (result) {
        refetch();
        setOpenDialog(false);
        setIsLoading(false);
        setOpen(false);
      }
      // console.log("refunded :-", result);
    }
    if (status == "completed") {
      // console.log("completedf");
      setIsLoading(true);
      const result = await OrderStatusUpdate({
        item: selectedItem,
        orderStatus: status,
      });
      if (result) {
        refetch();
        setOpenDialog(false);
        setIsLoading(false);
        setOpen(false);
      }
      // console.log("completed :-", result);
    }
    if (status == "accepted") {
      // console.log("acceptedf");
      setIsLoading(true);
      const result = await acceptOrder({
        id: selectedItem._id.toString(),
      });
      if (result) {
        refetch();
        setOpenDialog(false);
        setIsLoading(false);
        setOpen(false);
      }
      // console.log("accepted :-", result);
    }
    if (status == "cancelled") {
      // console.log("cancelledf");
      setIsLoading(true);
      const result = await cancelOrder({
        id: selectedItem._id.toString(),
      });
      if (result) {
        refetch();
        setOpenDialog(false);
        setIsLoading(false);
        setOpen(false);
      }
      // console.log("cancelled :-", result);
    }
    refetch();
  };

  // console.log("seletedStatus", status);
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure want to Update?"}
        </DialogTitle>

        <DialogActions>
          {!isLoading ? <Button onClick={handleCloseDialog}>No</Button> : ""}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Yes
            </LoadingButton>
          ) : (
            <Button onClick={handleClickUpdate} autoFocus>
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <div className="order-main-div">
        <Stack spacing={2}>
          <Item>
            {" "}
            <div className="order-details-row">
              <div className="order-details-element">
                <h4>Order # :</h4>
                <h4>{specificOrder?.refNumber}</h4>
              </div>{" "}
              <div className="order-details-element">
                <h4>Order Status :</h4>
                <h4>
                  <TextField
                    id="outlined-select-currency"
                    select
                    // label="Type*"
                    fullWidth
                    value={status}
                    onChange={(newValue) => {
                      setStatus(newValue.target.value);
                      // console.log(newValue.target.value);
                    }}
                  >
                    {statusTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </h4>
                <h4>
                  <Button
                    variant="contained"
                    sx={tableHeadStyle}
                    onClick={() => {
                      setSelectedItem(specificOrder);
                      setOpenDialog(true);
                    }}
                  >
                    Update
                  </Button>
                </h4>
              </div>
            </div>
          </Item>
          <Item>
            <div className="order-details-row">
              <div className="order-details-element">
                <h4>Date:</h4>
                <h4>{formatDate(specificOrder?.date)}</h4>
              </div>
              <div className="order-details-element">
                <h4>Customer:</h4>
                <h4>{`${specificOrder?.customer?.firstName}  ${specificOrder?.customer?.lastName}`}</h4>
              </div>
            </div>
          </Item>
          <Item>
            {" "}
            <div className="order-details-row">
              <div className="order-details-element">
                <h4>Payment Status :</h4>
                <h4>{specificOrder?.paymentStatus}</h4>
              </div>
              <div className="order-details-element">
                <h4>Provider:</h4>
                <h4>{`${specificOrder?.provider?.name?.en}( ${specificOrder?.provider?.name?.ar})`}</h4>
              </div>
            </div>
          </Item>
          <Item>
            {" "}
            <div className="order-details-row">
              <div className="order-details-element">
                <h4>Total Amount :</h4>
                <h4>{formatCurrency(specificOrder?.amount)}</h4>
              </div>
              <div className="order-details-element">
                <h4>Address:</h4>
                <h4>{`${specificOrder?.address?.firstName}  ${specificOrder?.address?.lastName}, ${specificOrder?.address?.address}`}</h4>
              </div>
            </div>
          </Item>
          <Item>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell align="right">Oty</TableCell>
                    <TableCell align="right">Addons</TableCell>
                    <TableCell align="right">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {specificOrder?.items?.map((row) => (
                    <TableRow
                      key={row._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.name?.en || row?.item?.name?.en}
                      </TableCell>
                      <TableCell align="right">{row?.quantity}</TableCell>
                      <TableCell align="right">
                        {row?.modifiers?.map((value, index) => (
                          // console.log(value?.modifierName?.en)
                          <h6
                            className="order-addon-h6"
                            key={index}
                          >{`${value?.modifierName?.en},`}</h6>
                        ))}
                      </TableCell>
                      <TableCell align="right">
                        {formatCurrency(row?.price || row?.item?.price)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Item>
        </Stack>
      </div>
    </>
  );
};

export default OrderDetail;
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.9rem",
  },
};
