import React, { useState, useEffect } from "react";
import * as Realm from "realm-web";
import "../../../css/providers.css";
import envConfig from '../../../env/env.json';
import { Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import { IKContext, IKImage, IKUpload } from "imagekitio-react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  urlEndpoint,
  publicKey,
  authenticationEndpoint,
} from "../../../Common/ImageKit/ImageKitCred";
import { UpdateOneProvider, AddOneProvider } from "../../../Services/dbService";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
const providerApp = new Realm.App({ id: envConfig.MONGODB_PROVIDER_APP_ID });

const AddProviders = ({ setOpen, edit, specificProvider = null, refetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [resIcon, setResIcon] = useState({});
  const [resBanner, setResBanner] = useState({});
  const [resIconLoading, setResIconLoading] = useState(false);
  const [resBannerLoading, setResBannerLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required(" Name is required"),
    arabicName: Yup.string().required("اسم  is required"),
    englishBio: Yup.string().required("Description is required"),
    arabicBio: Yup.string().required("وصف is required"),
    number: Yup.string().required("Number is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const addNewProviders = async (data) => {
    // console.log("addNewCategory:", data);
    if (edit) {
      // console.log("editProduct ", data);
      setIsLoading(true);
      const result = await UpdateOneProvider({
        item: specificProvider,
        data,
        resIcon,
        resBanner,
      });
      // console.log("Editresult:", result);
      setOpen(false);
      setIsLoading(false);
      refetch();
    } else {
      // console.log("addProduct ", data);
      setIsLoading(true);
      const result = await AddOneProvider({
        data,
        resIcon,
        resBanner,
      });
      // console.log("Addresult:", result);
      if (result) {
        const registered = await providerApp.emailPasswordAuth.registerUser({
          email: data.email,
          password: data.password,
        });
        // console.log("user registered in Provider:", registered);
      }

      setOpen(false);
      setIsLoading(false);
      refetch();
    }
  };

  useEffect(() => {
    setValue("englishName", specificProvider?.name?.en);
    setValue("arabicName", specificProvider?.name?.ar);
    setValue("email", specificProvider?.email);
    setValue("englishBio", specificProvider?.description?.en);
    setValue("arabicBio", specificProvider?.description?.ar);
    setValue("number", specificProvider?.number);
    setResIcon(specificProvider?.icon);
    setResBanner(specificProvider?.mainImage);
  }, []);

  const onErrorIcon = (err) => {
    // console.log("ErrorIcon::", err);
  };

  const onSuccessIcon = (res) => {
    // console.log("SuccessIcon", res);
    setResIcon(res);
    setResIconLoading(false);
  };
  const onUploadStartIcon = (evt) => {
    // console.log("Start", evt);
    setResIconLoading(true);
  };

  const onErrorBanner = (err) => {
    // console.log("ErrorBanner", err);
  };

  const onSuccessBanner = (res) => {
    // console.log("SuccessBanner", res);
    setResBanner(res);
    setResBannerLoading(false);
  };
  const onUploadStartBanner = (evt) => {
    // console.log("Start", evt);
    setResBannerLoading(true);
  };
  return (
    <div>
      {" "}
      <form>
        <div className="providers-text-field">
          <div className="providers-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Name*"
              id="fullWidth"
              size="small"
              {...register("englishName", { required: true })}
              error={errors.englishName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.englishName && errors.englishName?.message
                ? errors.englishName?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="providers-text-field-row">
            <TextField
              fullWidth
              label="اسم*"
              id="fullWidth"
              size="small"
              {...register("arabicName", { required: true })}
              error={errors.arabicName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.arabicName && errors.arabicName?.message
                ? errors.arabicName?.message.toString()
                : null}
            </Typography>
          </div>
        </div>
        <div className="providers-text-field">
          <div className="providers-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Description*"
              id="fullWidth"
              size="small"
              {...register("englishBio", { required: true })}
              error={errors.englishBio ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.englishBio && errors.englishBio?.message
                ? errors.englishBio?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="providers-text-field-row">
            <TextField
              fullWidth
              label="وصف*"
              id="fullWidth"
              size="small"
              {...register("arabicBio", { required: true })}
              error={errors.arabicBio ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.arabicBio && errors.arabicBio?.message
                ? errors.arabicBio?.message.toString()
                : null}
            </Typography>
          </div>
        </div>
        <div className="providers-text-field">
          <div className="providers-text-field-row">
            <TextField
              fullWidth
              label="Number"
              id="fullWidth"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+971</InputAdornment>
                ),
              }}
              {...register("number", { required: true })}
              error={errors.number ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.number && errors.number?.message
                ? errors.number?.message.toString()
                : null}
            </Typography>
          </div>
          {edit && <div className="providers-text-field-row"></div>}
          {!edit && (
            <div className="providers-text-field-row">
              <TextField
                fullWidth
                label="Email*"
                id="fullWidth"
                size="small"
                {...register("email", { required: true })}
                error={errors.email ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.email && errors.email?.message
                  ? errors.email?.message.toString()
                  : null}
              </Typography>
            </div>
          )}
        </div>
        <div className="providers-text-field">
          {!edit && (
            <div className="providers-text-field-row">
              {" "}
              <TextField
                fullWidth
                label="Password*"
                id="fullWidth"
                size="small"
                {...register("password", { required: true })}
                error={errors.password ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.password && errors.password?.message
                  ? errors.password?.message.toString()
                  : null}
              </Typography>
            </div>
          )}
          <div className="providers-text-field-row"></div>
        </div>
        <div className="providers-text-field">
          <div
            className="col-md-6"
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            <h5>Icon:</h5>
            {resIcon && resIcon.filePath ? (
              <div
                style={{
                  width: 35,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  marginTop: 10,
                 
                }}
              >
                <div
                  style={{
                    position: "relative",
                    
                  }}
                >
                  <IKImage
                    urlEndpoint={urlEndpoint}
                    path={resIcon.filePath}
                    width="50px"
                    height="50px"
                  />
                  {resIcon.filePath && (
                    <div
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        zIndex: "1",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // setImage(null);
                        setResIcon(null);
                      }}
                    >
                      <AiOutlineCloseCircle
                        size={16}
                        color="red"
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                          // color: 'red',
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <IKContext
                publicKey={publicKey}
                urlEndpoint={urlEndpoint}
                authenticationEndpoint={authenticationEndpoint}
              >
                {resIconLoading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <IKUpload
                    onError={onErrorIcon}
                    onSuccess={onSuccessIcon}
                    onUploadStart={onUploadStartIcon}
                    buttonProps={{ children: "+" }}
                  />
                )}
              </IKContext>
            )}
          </div>
          <div
            className="col-md-6"
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            <h5>Banner:</h5>
            {resBanner && resBanner.filePath ? (
              <div
                style={{
                  width: 35,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <IKImage
                    urlEndpoint={urlEndpoint}
                    path={resBanner.filePath}
                    width="50px"
                    height="50px"
                  />
                  {resBanner.filePath && (
                    <div
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        zIndex: "1",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // setImage(null);
                        setResBanner(null);
                      }}
                    >
                      <AiOutlineCloseCircle
                        size={16}
                        color="red"
                        style={{
                          position: "absolute",
                          top: -10,
                          right: -10,
                          // color: 'red',
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <IKContext
                publicKey={publicKey}
                urlEndpoint={urlEndpoint}
                authenticationEndpoint={authenticationEndpoint}
              >
                {resBannerLoading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <IKUpload
                    onError={onErrorBanner}
                    onSuccess={onSuccessBanner}
                    onUploadStart={onUploadStartBanner}
                    buttonProps={{ children: "+" }}
                  />
                )}
              </IKContext>
            )}
          </div>
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
            marginTop:'30px'
          }}
        >
          {!isLoading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewProviders)}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  );
};

export default AddProviders;
