import React from "react";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton } from "@mui/material";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import LogoutPage from "./Logout";
/// Image
import profile from "../../../images/profile/12.png";
import avatar from "../../../images/avatar/1.jpg";
import {
  useCurrentProvider,
  useProvider,
} from "../../../Common/Hooks/queryCalls";

const Header = ({ onNote, toggle, onProfile, onActivity, onNotification }) => {
  // const providerId = "5eda1de77e3e21512c21940f";
  // const currentProvider = useCurrentProvider();
  // const { data, isLoading } = useProvider({
  //   providerId: currentProvider?.data?._id,
  // });
  // if (isLoading) {
  //   return <h1>Loading</h1>;
  // }
  // if (!data) {
  //   return <h1>Loading</h1>;
  // }

  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("form")
    ? filterName.filter((f) => f !== "form")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;

  var page_name =
    finalName.join(" ") === "" ? "Dashboard" : finalName.join(" ");

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {page_name}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              <li
                className={`nav-item dropdown header-profile ${
                  toggle === "profile" ? "show" : ""
                }`}
                onClick={() => onProfile()}
              >
                <Link
                  to={"#"}
                  className="nav-link"
                  role="button"
                  data-toggle="dropdown"
                >
                  <div className="header-info">
                    <small>Loko Admin</small>
                    {/* <span>{data?.name?.en}</span> */}
                    <IconButton>
                      <SettingsIcon fontSize="12px" sx={{ color: "#2b445a" }} />
                    </IconButton>
                  </div>
                  {/* <img src={profile} width="20" alt="" /> */}
                </Link>
                <div
                  className={`dropdown-menu dropdown-menu-right ${
                    toggle === "profile" ? "show" : ""
                  }`}
                >
                  {/* <Link to="/app-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <span className="ml-2">Profile </span>
                  </Link>
                  <Link to="/email-inbox" className="dropdown-item ai-icon">
                    <svg
                      id="icon-inbox"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-success"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                    <span className="ml-2">Inbox </span>
                  </Link> */}
                  <LogoutPage />
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
