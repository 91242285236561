import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  getAllCustomer,
  getAllProvider,
  getAllCategory,
  getAllCity,
  getAllOrders,
  getNotApprovedProviders,
  getNotApprovedBranches,
  getNotApprovedMenus,
  getNotApprovedAddons,
  getNotActiveReview,
  getAllPages,
  getOrdersBtwDates,
  getOrdersThisyear,
  getOrdersThisMonth,
  getOrdersThisWeek,
  getOrdersThisDay,
  getProvidersSearch,
} from "../../Services/dbService";

export const useAllCustomer = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["allCustomer", authUser], () => getAllCustomer());
};

export const useAllProvider = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["allProvider", authUser], () => getAllProvider());
};

export const useAllCategory = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["allCategory", authUser], () => getAllCategory());
};

export const useAllCity = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["allCity", authUser], () => getAllCity());
};

export const useAllOrders = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["allOrders", authUser], () => getAllOrders());
};

export const useNotApprovedProviders = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["notApprovedProviders", authUser], () =>
    getNotApprovedProviders()
  );
};

export const useNotApprovedBranches = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["notApprovedBranches", authUser], () =>
    getNotApprovedBranches()
  );
};
export const useNotApprovedMenus = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["notApprovedMenus", authUser], () => getNotApprovedMenus());
};
export const useNotApprovedAddons = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["notApprovedAddons", authUser], () =>
    getNotApprovedAddons()
  );
};

export const useNotActiveReview = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["notActiveReview", authUser], () => getNotActiveReview());
};

export const useProvidersSearch = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["branches", authUser], () => getProvidersSearch());
};

export const useAllPages = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["allPages", authUser], () => getAllPages());
};

export const useOrdersBtwDates = ({ startDate, endDate, providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(
    ["btwdates", authUser, { startDate, endDate, providerId }],
    () => getOrdersBtwDates({ startDate, endDate, providerId })
  );
};

export const useOrdersThisYear = ({ startOfYear, endOfYear, providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(
    ["thisYear", authUser, { startOfYear, endOfYear, providerId }],
    () => getOrdersThisyear({ startOfYear, endOfYear, providerId })
  );
};

export const useOrdersThisMonth = ({ startOfMonth, endOfMonth, providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(
    ["btwdates", authUser, { startOfMonth, endOfMonth, providerId }],
    () => getOrdersThisMonth({ startOfMonth, endOfMonth, providerId })
  );
};

export const useOrdersThisWeek = ({ startOfWeek, endOfWeek, providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(
    ["btwdates", authUser, { startOfWeek, endOfWeek, providerId }],
    () => getOrdersThisWeek({ startOfWeek, endOfWeek, providerId })
  );
};

export const useOrdersTodays = ({ startTodays, endTodays, providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(
    ["btwdates", authUser, { startTodays, endTodays, providerId }],
    () => getOrdersThisDay({ startTodays, endTodays, providerId })
  );
};

