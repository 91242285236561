import React from 'react'
import { formatDate, formatCurrency } from "../../../Services/functions";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ViewBranchDetails = ({ specificBranch }) => {
  return (
    <div className="approvals-main-div">
    <Stack spacing={2}>
      <Item>
        {" "}
        <div className="approvals-details-row">
          <div className="approvals-details-element">
            <h4>Name(EN):</h4>
            <h4>{specificBranch?.name?.en}</h4>
          </div>{" "}
          <div className="approvals-details-element">
            <h4>Name(AR):</h4>
            <h4>{specificBranch?.name?.ar}</h4>
          </div>
        </div>
      </Item>
      <Item>
        <div className="approvals-details-row">
          <div className="approvals-details-element">
            <h4>ProviderName(EN):</h4>
            <h4>{specificBranch?.provider?.name?.en}</h4>
          </div>
          <div className="approvals-details-element">
            <h4>ProviderName(AR):</h4>
            <h4>{specificBranch?.provider?.name?.ar}</h4>
          </div>
        </div>
      </Item>
      <Item>
        {" "}
        <div className="approvals-details-row">
          <div className="approvals-details-element">
            <h4>Is Approved :</h4>
            <h4>{specificBranch?.isApproved ?  <CheckCircleIcon /> : <CancelIcon />}</h4>
          </div>
          <div className="approvals-details-element">
            <h4>Is Active:</h4>
            <h4>{specificBranch?.metadata?.isActive ?  <CheckCircleIcon /> : <CancelIcon />}</h4>
          </div>
        </div>
      </Item>
    </Stack>
  </div>
  )
}

export default ViewBranchDetails